import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import Portfolio from "../components/portfolio/Portfolio";
import Home from "../components/Home";
import AboutMain from "../components/about/AboutMain";
import ServiceMain from "../components/service/ServiceMain";
import Blog from "../components/blog/Blog";
import Podcast from "../components/podcast/Podcast";
import LinkTree from "../views/all-home-version/LinkTree";
import UnsubscribePage from "../views/all-home-version/UnSubscribePage";
import Infographic from "../components/infographics/Infographic";
import ContentHub from "../components/contenthub/ContentHub"

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLight />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:i" element={<Portfolio />} />
          <Route path="/services" element={<ServiceMain />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:i" element={<Blog />} />
          <Route path="/infographic" element={<Infographic />} />
          <Route path="/infographic/:i" element={<Infographic />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route path="/podcast/:i" element={<Podcast />} />
          <Route path="/contenthub" element={<ContentHub />} />
          <Route path="/contenthub/quotes/:i" element={<ContentHub />} />
          <Route path="/contenthub/book/:i" element={<ContentHub />} />
          <Route path="/contenthub/talk/:i" element={<ContentHub />} />
          <Route path="*" element={<NotFound />} />
        </Route> 
        {/* Ruta independiente */}
        <Route path="/links" element={<LinkTree />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
