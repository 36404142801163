
  const podcastPods = [
    {
      id: 3,
      title: "Amazon’s Working Backwards",
      subtitle: "Let’s Talk About Innovation",
      image: "/assets/podcast/workingbackwards.png",
      event: "working_backwards_podcast_view",
      tags: "#amazon #innovation #workingbackwards",
      date: "21 February 2025",
      path: "/podcast/workingbackwards",
      url: "https://asiergonzalez.es/podcast/workingbackwards",
      podcastpath: "https://open.spotify.com/embed/show/3btLqh2WmXh7mZUTRYFN0z?utm_source=generator"
    }
    ,{
      id: 2,
      title: "The Art of the Startup Pitch",
      subtitle: "Techpreneurs: Innovating the Future",
      image: "/assets/podcast/pitchdeckstructure.png",
      event: "pitch_deck_structure_podcast_view",
      tags: "#pitchdeck",
      date: "17 January 2025",
      path:"/podcast/pitchdeckstructure",
      url:"https://asiergonzalez.es/podcast/pitchdeckstructure",
      podcastpath:"https://open.spotify.com/embed/episode/1Jam5g07JDoqL9cNPul4tL?utm_source=generator"
    },
    {
      id: 1,
      title: "Best Practices of Angel Investors",
      subtitle: "Techpreneurs: Innovating the Future",
      image: "/assets/podcast/angelinvestors.png",
      event: "angel_investors_podcast_view",
      tags: "#angel #investors",
      date: "16 January 2025",
      path:"/podcast/angelinvestors",
      url:"https://asiergonzalez.es/podcast/angelinvestors",
      podcastpath:"https://open.spotify.com/embed/show/3btLqh2WmXh7mZUTRYFN0z?utm_source=generator"
    }
    // Add more posts as needed
  ];

  export default podcastPods;
