import React from "react";
import Modal from "react-modal";
import Testimonial from "./Testimonial";
import Intro from "./Intro";
import KnowledgeInterest from "./KnowledgeInterest";
import PersonalInfo from "./PersonalInfo";
import Experience from "./Experience";
import Education from "./Education";

import Skills from "./Skills";
//import GA4React from "ga-4-react";
//const ga4react = new GA4React("G-YEFLGRFN92");

Modal.setAppElement("#root");
function DownloadCVAbout() {
  //ga4react.gtag('event', 'download_cv', {'event_category': 'download', 'event_label': 'about'});
  window.dataLayer.push({'event': 'download_cv'});

}

const AboutMain = () => {
  return (
    <>
      <div className="container">
        <div className="tokyo_tm_about">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>About</span>
                <h3>About Me</h3>
              </div>
            </div>
          </div>
          {/* End title */}
          <Intro />
          <div className="tokyo_tm_short_info">
            <PersonalInfo />
          </div>
          <div className="tokyo_tm_button" data-position="left">
           <a href="assets/img/AsierPitchDeck.pdf" target="_blank" rel="noreferrer" onClick={DownloadCVAbout} > 
              <span> Download</span>
            </a>
          </div> 
        </div>
      </div>
      <div className="tokyo_tm_resumebox">
        <div className="container">
          <div className="in">
            <Experience />
          </div>
        </div>
      </div>
      {/* End tokyo_tm_resumebox */}
      <div className="tokyo_tm_resumebox" style={{backgroundColor:"#FFFFFF"}}>
        <div className="container">
          <div className="in">
            <Education />
          </div>
        </div>
      </div>
      {/* End tokyo_tm_resumebox */}

      <div className="tokyo_tm_skillbox">
        <div className="container">
          <div className="in">
            <KnowledgeInterest />
          </div>
        </div>
      </div>
      {/* End .tokyo_tm_skillbox */}

      <div className="tokyo_tm_progressbox">
        <div className="container">
          <div className="in">
            <Skills />
          </div>
          {/* End in */}
        </div>
        {/* End .container */}
      </div>
      {/* End tokyo_tm_progressbox */}

      <div className="tokyo_tm_testimonials">
        <div className="container">
          <div className="tokyo_section_title">
            <h3>Recommendations</h3>
          </div>
          <div className="list">
            <Testimonial />
          </div>
        </div>
      </div>
      {/* End tokyo_tm_testimonials */}

      {/* /ABOUT */}
    </>
  );
};

export default AboutMain;
