import React, { useState, useEffect } from "react";
import Social from "../Social";
import "./linktree.css";
import SubscribeForm from "../mailchimp/SubscribeForm"
const Links = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  // Efecto para mostrar el popup automáticamente
/*   useEffect(() => {
    const popupDismissed = localStorage.getItem("doNotShowPopup");
    if (!popupDismissed) {
      setIsPopupVisible(true);
    }
  }, []);
 */
  // Guardar la preferencia de no mostrar el popup
  const handleDoNotShowAgain = () => {
    setDoNotShowAgain(!doNotShowAgain);
  };

  const handleClosePopup = () => {
    if (doNotShowAgain) {
      localStorage.setItem("doNotShowPopup", "true");
    }
    setIsPopupVisible(false);
  };

  return (
    <div
      className="linktree"
      style={{
        backgroundImage: "url(/assets/img/back.png)",
      }}
    >
      <div className="mask" style={{ backgroundColor: "rgba(51, 59, 77, 0.95)" }}></div>

      <div className="content">
        <header className="header">
          <div className="profileImage">
            <img src="assets/img/slider/1.png" alt="Asier Gonzalez" />
          </div>
          <h2>Asier Gonzalez</h2>
        </header>
        <div>
          <a href="https://asiergonzalez.es/infographic" className="linkButton">My Infographics</a>
          <a href="https://asiergonzalez.es/podcast" className="linkButton">My Podcasts</a>
          <a href="https://asiergonzalez.es/blog" className="linkButton">My Blog</a>
          <a href="https://www.youtube.com/@asierglez" className="linkButton">My Videos</a>
          <a href="https://www.linkedin.com/in/asiergonzalez/" className="linkButton">My LinkedIn</a>
          <a href="https://www.instagram.com/asiergonzalez.es/" className="linkButton">My Instagram</a>
          <a href="https://x.com/AsierGlez" className="linkButton">My Twitter</a>
        </div>
        <Social />
      </div>

      {/* Popup */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              className="close-popup"
              onClick={handleClosePopup}
            >
              ×
            </button>
            <h5>Subscribe to Newsletter</h5>
            
            <div className="subscribe-container">
              <SubscribeForm />
            </div>
            <div style={{ marginTop: "10px" }}>
              <label style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={doNotShowAgain}
                  onChange={handleDoNotShowAgain}
                  style={{ marginRight: "8px" }}
                />
                Don't show this again
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Links;