import React from "react";

const KnowledgeInterest = () => {
  const knowledgeContent = [
    {
      id: 1,
      colClass: "left",
      title1: "Other Studies",
      content1: [
        "2024 · Mentoring Fundamentals · IESE ",
        "2024 · DISRUPT: Open Innovation in Corporations · BackFund Academy ",
        "2024 · RAISE GO: Prepare Investment Rounds · BackFund Academy",
        "2024 · 3D Printing and Modelling · Udemy ",
        "2024 · Professional Transition Program · IESE ",
        "2024 · ChatGPT - Productivity and Creativity with Prompt Engineering · Udemy ",
        "2023 · Anonymity and privacy for Ethical Hacking and Cybersecurity by Santiago Hernandez · Udemy ",
        "2023 · Complete Ethical Hacking Course by Santiago Hernandez · Udemy",
        "2023 · Understanding Security in the IoT Ecosystem · (ISC)² ",
        "2023 · Cybersecurity in IoT · Curtin University",
        "2023 · Virtual Course about 5G Technology · COIT ",
        "2020 · MSFT Azure Cloud Bootcamp · Udemy",
        "2020 · Blockchain with Ethereum · Udemy",
        "2019 · IDboxRt - Industrial Processes Platform · IDBOX",
        "2019 · Visio and Unistream PLC programming · SIDE",
        "2016 · Angular.js Basics · DEVCODE",
        "2015 · Smart Cities · Open University",
        "2015 · SigFoxMaker Tour · SIGFOX",
        "2015 · Smart Cities - Infrastructures and Services · COIT",
        "2015 · Internet of Things · Kings College London",
        "2015 · Curso Onduladora · AFCO",
        "2013 · Project Management PMP · COIT",
        "2013 · Wi-Fi, Wimax and other wireless networks. Technology and Applications · COIT",
        "2013 · Libelium Training Course · LIBELIUM",
        "2013 · Java Course · Videotutoriales.com",
        "2011 · EUSKALIT-Directiv@21 y Lider21 · BASQUE GOVERMENT",
        "2011 · Technical Seminar: Telecommunications Policy and Market Surveillance · TECNALIA",
        "2012 · UNE-EN-ISO 9001:2008 · CONSULTEC",
        "2010 · AutoCAD 2009 2D Drawing · IBEX",
      ] ,
      title2: "Organizations",
      content2: [
        " IESE Mentor . Universidad de Navarra",
        " IESE Alumni . Universidad de Navarra",
        " COIT · Official College of Telecommunication Engineering",
        " AEPIA · Spanish Association for Artificial Intelligence",
        " IEEE - Iot and SmartCities Membership",
      ],   
      title3: "",
      content3: [ ],
   
    },
    {
      id: 2,
      colClass: "right",
      title1: "Publications",
      content1: [
        "Mejora en la eficiencia y seguridad en el consumo de agua en edificios aplicando sistemas ciber-físicos · TECMARED · 08.06.2017 ",
        "Wastewater treatment with the Internet of Things · ENGINEERING.COM · 18.05.2017 ",
        "iMETland: Una segunda vida para las aguas residuales rurales a través de la tecnología · TECMARED · 04.05.2017",
        "Sistema Inteligente de Gestión del Agua Desarrollado en una escuela primaria de Rivas Vaciamadrid dentro del programa estratégico luz verde de la ciudad · TECMARED · 25.07.2017",
        "Saving Water with Smart Management and Efficient Systems in Spain · LIBELIUM · 10.06.2017 ",
        "Monitorización y Control de Humedales para optimizar el proceso de depuración de aguas residuales urabanas en comunidades inteligentes sostenibles · TECMARED · 13.04.2016 ",
        "Smart Farming:Monitoring Horses and Equine Facility Management with Waspmote · LIBELIUM· 07.04.2015 ",
        "La tecnología al servicio del caballo · REVISTA ECUESTRE · 07.02.2015 ",
      ],
      title2: "Awards",
      content2: [
        "Speaker at Jornadas Aguas de Burgos 2018",
        "Speaker at Libelium Conference 2018",
        "Webinar for Libelium 2017",
        "Speaker at Madrid Monitoring Day 2017",
        "Speaker at Madrid Monitoring Day 2016",
        "Concession Development Program of Innovative Business Projects in Basque Country in 2013",
      ],
      title3: "Certifications",
      content3: [
        "IDBOX Platform Integrator",
      ],
    },
  ];

  return (
    <>
      {knowledgeContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_section_title">
            <h3>{item.title1}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content1.map((val, i) => (
                <li key={i} >
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>
         

          <div className="tokyo_section_title">
            <h3><br></br><br></br>{item.title2}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content2.map((val, i) => (
                <li key={i}>
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>


          <div className="tokyo_section_title">
            <h3><br></br><br></br>{item.title3}</h3>
          </div>
          <div className="tokyo_tm_skill_list">
            <ul>
              {item.content3.map((val, i) => (
                <li key={i}>
                  <span>
                    <img
                      className="svg"
                      src="assets/img/svg/rightarrow.svg"
                      alt="arrow"
                    />
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default KnowledgeInterest;
