const quotes = [
  {
    "id": 20,
    "author": "Asier Gonzalez",
    "where": "The Valley - IBM Event, Madrid",
    "category": "Artificial Intelligence & Human Leadership",
    "date": "2025",
    "image": "/assets/quotes/20.png",
    "content": `
      <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="quote icon"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">
          "The competitive advantage will depend on who has the best generative AI."
        </p>
      </div>
      <p>This insight was shared at The Valley in Madrid, during an event with IBM and Miguel Ángel Morcuende. It’s a statement about AI, but it’s really a statement about people.</p>
      <br>
      <p>We often focus on technology when talking about AI, but what truly makes a difference is how people and culture embrace it. The biggest challenges ahead are not technical—they’re human:
      - **Explainability** – So everyone understands and trusts AI decisions.
      - **Ethics** – So AI serves people and society responsibly.
      - **Bias** – So we foster fairness and inclusion.
      - **Trust** – So people feel confident in these new tools.</p>
      <br>
      <p>According to McKinsey, 70% of transformations fail. Gartner tells us why: organizations struggle to activate real **cultural change**. Without it, no AI strategy will succeed.</p>
      <br>
      <p>What we need to focus on:
      - **Building trust through transparency**
      - **Leading with purpose and ethics**
      - **Empowering people to work with AI, not for AI**
      - **Ensuring cultural alignment and collaboration**</p>
      <br>
      <p>The next wave of productivity won’t come from technology alone. It will come from people working together, embracing change, and driving ethical AI from within.</p>
    `,
    "event": "the_valley_ibm_ai_productivity",
    "tags": " #Leadership #PeopleAndCulture #TrustInAI",
    "path": "/contenthub/quotes/aiwithpurpose",
    "url": "https://asiergonzalez.es/contenthub/quotes/aiwithpurpose"
},{
    id: 19,
    author: "Warren Buffett",
    where: "Business Strategy Insights",
    category: "Entrepreneurs",
    date: "2024",
    image: "/assets/quotes/19.png",
    content: `
      <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="quote icon"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">
          "Hire the best and let them do what they know. If not, hire the cheapest and make them do what you say."
        </p>
      </div>
      <p>This quote might sound tongue-in-cheek, but it highlights how crucial trust and empowerment are in the workplace. If you’re bringing in top talent, let them innovate, lead, and grow—otherwise, you’re missing out on the real value they can offer.</p>
      <br>
      <p>Here’s what it reminds us to focus on:
      - **Trust in Expertise** – Great hires bring new perspectives and energy.
      - **Empowerment** – Autonomy drives motivation and creativity.
      - **Cultural Alignment** – Hire people who share and enhance your core values.
      - **Retention** – Talented people stay when they feel they have a voice.</p>
      <br>
      <p>Ultimately, hiring should be an investment in brilliance and capability—anything less is just ticking a box.</p>
    `,
    event: "business_hire_strategy",
    tags: "#BusinessStrategy #Hiring #Entrepreneurship #Leadership",
    path: "/contenthub/quotes/hirethebest",
    url: "https://asiergonzalez.es/contenthub/quotes/hirethebest"
  }
  ,{
    id: 18,
    author: "Warren Buffett",
    where: "Business Strategy Insights",
    category: "Entrepreneurs",
    date: "2024",
    image: "/assets/quotes/18.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">"A good business is like a strong castle with a deep moat around it. I want sharks in the moat. I want it untouchable."</p>
      </div>
    <p>Innovation is not enough. A truly successful business must be defensible—protected by a strategic moat that makes it difficult to replicate or compete with.</p>
    <br>
    <p>Warren Buffett’s analogy of a **castle with a deep moat** is more relevant than ever. Today’s most resilient companies don’t just create value; they protect it.</p>
    <br>
    <p>Building a strong moat involves:  
    - **Unique Technology** – Setting the benchmark, not following trends.  
    - **Scalable Business Model** – Ensuring that growth strengthens your position.  
    - **A Robust Ecosystem** – Creating interconnected value, like Amazon or Apple.  
    - **A Powerful Brand** – Becoming the first and only choice for your audience.  
    - **A Loyal Customer Base** – Keeping competitors at bay through strong retention.</p>
    <br>
    <p>Without a moat, even the most innovative ideas are vulnerable. The question is not just what you're building, but how you're defending it.</p>
    `,
    event: "business_moat_strategy",
    tags: "#Moat #Entrepreneurship #Innovation",
    path: "/contenthub/quotes/businessmoat",
    url: "https://asiergonzalez.es/contenthub/quotes/businessmoat"
}
,{
    id: 17,
    author: "Carlos Blanco",
    where: "Startup Webinar",
    category: "Entrepreneurs",
    date: "13 February 2025",
    image: "/assets/quotes/16.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">"A startup is: team + market + execution."</p>
      </div>
    <p>During a webinar with <strong>Carlos Blanco</strong>, the discussion centered on what truly makes startups succeed. While ideas are important, execution is what separates winners from those who never take off.</p>
    <br>
    <p>Timing is crucial. Arriving too early or too late can be fatal. Equally important is assembling the right team—many projects fail before they even begin due to missing or misaligned co-founders. Choosing the wrong partners is a common mistake, and sometimes, it’s better not to launch at all.</p>
    <br>
    <p>On equity distribution, Carlos emphasized that shares should reflect contributions, not be divided equally. The rise of AI is also changing how startups operate, shortening development cycles and lowering MVP costs to €20,000-50,000.</p>
    <br>
    <p>The first priority in any startup? **Sales.** There are only two types of salespeople—those who don’t sell and those who are too aggressive. But beyond initial traction, the real test is retention. A **low churn rate and strong unit economics matter more than top-line growth.**</p>
    <br>
    <p>The lesson is clear: **An MVP must generate sales and provide tangible value.** Without that, it's just a product—not a business.</p>
    `,
    event: "carlosblanco_webinar",
    tags: "#Startups #Entrepreneurship #Execution",
    path: "/contenthub/quotes/carlosblanco",
    url: "https://asiergonzalez.es/contenthub/quotes/carlosblanco"
  },
  {
    id: 15,
    author: "Irene Gómez",
    where: "Wayra · Telefónica Ventures",
    category: "Entrepreneurs",
    date: "11 February 2025",
    image: "/assets/quotes/15.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">"Markets are becoming more extreme—AI and everything else."</p>
      </div>
    <p>The startup ecosystem has become highly professionalized, with serial entrepreneurs, structured venture capital, and established innovation hubs. At the same time, capital has become increasingly concentrated in large U.S. funds, reinforcing a global imbalance in access to financing.</p>
    <br>
    <p>We are witnessing a growing divide—AI dominates investment and strategic focus, while other sectors face challenges in securing attention and resources. This trend is shaping not only the funding landscape but also the broader direction of innovation.</p>
    <br>
    <p>Technology is no longer a differentiator; it is a fundamental enabler. However, having access to technology is not enough. Success requires **a clear direction and a strategic approach**. A holistic perspective—integrating external talent, fostering entrepreneurship, and ensuring that innovation aligns with business objectives—is now critical.</p>
    `,
    event: "wayra_quotes_view",
    tags: "#Wayra #VentureCapital #Execution #Startups",
    path: "/contenthub/quotes/wayra",
    url: "https://asiergonzalez.es/contenthub/quotes/wayra"
},{
    id: 14,
    author: "Raul del Pozo",
    where: "Global Industry Forum · 2024",
    category: "Entrepreneurs",
    date: "2024",
    image: "/assets/quotes/14.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Los emprendedores estan destruyendo y recontruyendo creativamente todos los modelos de negocio que estaban establecidos.</p>
      </div>
    <p>Asistiendo al Global Industry Forum, un evento que esta reuniendo a líderes y expertos para debatir sobre el futuro de la industria. La apertura de Federico De Juan me ha parecido una forma muy ingeniosa de romper el hielo con gran maestría y de establecer a su vez un tono de excelencia y profesionalidad.</p>
    <br>
    <p>Me quedo del inicio con la gran frase de apertura de Raúl del Pozo con Peter Howitt de referencia: “ Los emprendedores están destruyendo y reconstruyendo creativamente todos los modelos de negocio que estaban establecidos ”.</p>
    `,
    event: "delpozo_quotes_view",
    tags: "#rauldelpozo #marketing #innovation",
    path:"/contenthub/quotes/rauldelpozo",
    url:"https://asiergonzalez.es/contenthub/quotes/rauldelpozo",
  },{
    id: 13,
    author: "Elena Peyro",
    where: "Global Industry Forum · 2024",
    date: "2024",
    category: "Marketing",
    image: "/assets/quotes/13.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
            <div style="position: relative;margin-bottom: 30px">
              <img
                style="width: 40px;height: 40px"
                src="/assets/img/svg/quote.svg"
                alt="tumb"
              />
            </div>
            <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Do not fall in love with your idea; fall in love with your customers</p>
          </div>
        <br>
        <p> Elena shared her entrepreneurial journey: creating a startup that became a leader in mobility, partnering with top IBEX 35 corporations, and excelling in the MICE sector with nearly 2,000 corporate events managed.</p><br>
        <p> My notes from her keynote:</p><br>
        <ul>
        <li>Don’t fall in love with your idea; fall in love with your customer.</li>
        <li>Entrepreneurship is a rollercoaster, but having a purpose is a competitive advantage.</li>
        <li>Success is built on three pillars: economic, social, and environmental impact.</li>
        </ul><br>
        <p>An inspiring story of innovation in mobility!</p>
    `,
    event: "peyro_quotes_view",
    tags: "#peyro #business #innovation",
    path:"/contenthub/quotes/peyro",
    url:"https://asiergonzalez.es/contenthub/quotes/peyro",
  },{
    id: 12,
    author: "Clayton M. Chistensen",
    where: "The Innovator's Dilema",
    date: "2025",
    category: "Innovation",
    image: "/assets/quotes/12.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
            <div style="position: relative;margin-bottom: 30px">
              <img
                style="width: 40px;height: 40px"
                src="/assets/img/svg/quote.svg"
                alt="tumb"
              />
            </div>
            <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Disruptives technologies tipically underperform in mainstream markets but thrive in new ones</p>
          </div>
        <br>
        <p>Are you targeting the right market for your disruptive technology?</p><br>
        <p>Disruptive technologies often fail to meet the expectations of established markets but excel in creating entirely new ones.</p><br>
        <p>Identifying the right audience is crucial for gaining traction and maximizing impact.</p><br>
        <p>#DisruptiveTech #MarketFit #InnovationStrategy</p>

    `,
    event: "clayton_quotes_view",
    tags: "#clayton #marketing #innovation",
    path:"/contenthub/quotes/clayton",
    url:"https://asiergonzalez.es/contenthub/quotes/clayton",
  },{
    id: 11,
    author: "Alicia Asín · CEO Libelium",
    where: "Toma el control de Tus Datos · 2024",
    category: "Data Control",
    date: "2024",
    image: "/assets/quotes/11.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
            <div style="position: relative;margin-bottom: 30px">
              <img
                style="width: 40px;height: 40px"
                src="/assets/img/svg/quote.svg"
                alt="tumb"
              />
            </div>
            <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">How Much Does the Algorithm Know About You?</p>
          </div>
        <br>
    <p>In today’s digital age, it’s not just that people are unaware of how much they reveal online; it’s that the algorithms understand them better than they understand themselves. Every click, search, and interaction feeds into a complex network of data analysis, making algorithms incredibly adept at predicting behaviors, preferences, and even future needs.</p><br>
    <p>This raises pivotal questions about privacy and data security. Are we comfortable with the amount of personal information that's accessible to technology companies? How can we ensure that this data is used ethically?</p><br>
    <p>As professionals navigating this digital era, we must advocate for transparency and control over our digital footprints. Let's engage in a discussion about how we can harness the benefits of big data while safeguarding our personal information against misuse.</p><br>
    <p>What are your thoughts on the balance between technological convenience and privacy?</p>
    `,
    event: "aliciaasin_quotes_view",
    tags: "#aliciaasin #data #innovation",
    path:"/contenthub/quotes/aliciaasin",
    url:"https://asiergonzalez.es/contenthub/quotes/aliciaasin",
  },{
    id: 10,
    author: "Tim Brown",
    where: "Change Design · 2009",
    date: "2024",
    category: "Innovation",
    image: "/assets/quotes/10.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
            <div style="position: relative;margin-bottom: 30px">
              <img
                style="width: 40px;height: 40px"
                src="/assets/img/svg/quote.svg"
                alt="tumb"
              />
            </div>
            <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">In innovation, what matters is not being right but asking the right questions</p>
          </div>
        <br>
    <p>Innovation is not about always having the perfect answers—it's about being brave enough to ask the right questions. The kind of questions that challenge the status quo, uncover hidden opportunities, and lead to groundbreaking solutions.</p><br>
    <p>In an era where knowledge is abundant, the differentiator is not knowing more but knowing how to question more effectively. By focusing on curiosity and critical thinking, we open doors to possibilities that we might not even have considered.</p><br>
    <p>Next time you face a challenge, instead of asking, "What should we do?", try asking, "What haven’t we explored yet?" or "Why are we doing things this way?"</p><br>
    <p>What are some of the most impactful questions you've asked in your journey of innovation? Let’s discuss!</p>
    `,
    event: "timbrown_quotes_view",
    tags: "#timbrown #innovation",
    path:"/contenthub/quotes/timbrown",
    url:"https://asiergonzalez.es/contenthub/quotes/timbrown",
  },{
    id: 9,
    author: "Steve Jobs",
    where: "Developing Macintosh at Apple",
    category: "Innovation",
    date: "2024",
    image: "/assets/quotes/9.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
            <div style="position: relative;margin-bottom: 30px">
              <img
                style="width: 40px;height: 40px"
                src="/assets/img/svg/quote.svg"
                alt="tumb"
              />
            </div>
            <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">It is more fun to be a pirate than to join the navy</p>
          </div>
        <br>
    <p>Startups embody the pirate spirit. They thrive on breaking the rules, challenging norms, and carving out paths where none existed before. While established corporations (the “navy”) may offer stability and structure, startups bring the thrill of charting new territories and innovating with boldness.</p><br>
    <p>But being a pirate isn’t just about rebellion—it’s about creating value in unconventional ways. It’s about agility, passion, and the relentless pursuit of solving problems differently. This mindset is what drives disruption and shapes industries.</p><br>
    <p>If you’re part of the startup world, you know that the journey is far from easy, but the freedom to build something meaningful from scratch makes it all worth it.</p><br>
    <p>Are you sailing with the pirates or navigating with the navy? What’s been your experience in either world? Let’s hear your thoughts!</p>
    `,
    event: "stevejobs_quotes_view",
    tags: "#stevejobs #innovation",
    path:"/contenthub/quotes/stevejobs",
    url:"https://asiergonzalez.es/contenthub/quotes/stevejobs",
  },{
    id: 8,
    author: "Steve Blank",
    where: "Why you cant tell a company be like a startup",
    category: "Innovation",
    date: "2024",
    image: "/assets/quotes/8.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Startups Can Do Anything, Companies Can Only Do What's Legal</p>
      </div>
    <br>
    <p>Startups operate in a world where creativity often outruns regulation. This freedom allows them to innovate, experiment, and disrupt industries in ways that established companies, bound by compliance and legacy systems, simply cannot.</p><br>
    <p>But with great freedom comes great responsibility. The most successful startups don’t just break rules—they rewrite them responsibly. They challenge outdated frameworks while building trust, ensuring their innovations are both groundbreaking and ethical.</p><br>
    <p>This dynamic is what makes startups so exciting. They’re the rebels with the opportunity to redefine “what’s possible,” while also setting new standards for the industries they disrupt.</p><br>
    <p>How do you think startups can balance innovation with responsibility? Let’s discuss!</p>
    `,
    event: "steveblank_quotes_view",
    tags: "#steveblank #startup #innovation",
    path:"/contenthub/quotes/steveblank",
    url:"https://asiergonzalez.es/contenthub/quotes/steveblank",
  },{
    id: 7,
    author: "Alice",
    where: "Alice in Wonderland · 2010",
    category: "Motivational",
    date: "2024",
    image: "/assets/quotes/7.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Nothing Is Impossible if You Try Hard Enough.</p>
      </div>
    <br>
    <p>In a world driven by challenges and constant innovation, this quote serves as a timeless reminder: perseverance is the bridge between the impossible and the achievable. Whether you're launching a startup, developing groundbreaking technology, or simply navigating the complexities of life, success often comes down to how much you're willing to try and adapt.</p><br>
    <p>Innovation thrives on this mindset. It’s not about having all the answers—it’s about refusing to give up when obstacles arise. The most transformative ideas were once considered impossible until someone dared to prove otherwise.</p>
    `,
    event: "alice_quotes_view",
    tags: "#alice #motivational #innovation",
    path:"/contenthub/quotes/alice",
    url:"https://asiergonzalez.es/contenthub/quotes/alice",
  },{
    id: 6,
    author: "Marian Rojas",
    where: "Planet25 · Madrid",
    category: "Psicology",
    date: "2025",
    image: "/assets/quotes/6.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">El cerebro recuerda lo que le calma, lo que le excita y lo que le da placer</p>
      </div>
    <br>
    <p>En la charla que he asistido en Madrid en <strong>#Planet25</strong> de <strong>#MarianRojas</strong> sobre si "¿Estamos fatal o estamos distintos?", la autora nos invita a reflexionar sobre cómo vivimos hoy: intoxicados de cortisol, inundados de dopamina y en una crisis profunda de atención.</p><br>
    <p>Pero no todo está perdido. Hay algo que puede salvarnos: la confianza y una actitud positiva hacia la vida. Si te hablas bien, hay más posibilidades de que las cosas salgan bien.</p><br>
    <p>Reflexión clave: "El cerebro recuerda lo que le calma, lo que le excita y lo que le da placer." Pero... ¿Estamos gestionando bien estas emociones?</p><br>
    <p>Encuentra momentos para la calma, aprende a tolerar pequeñas incomodidades y conecta con los demás desde la confianza. Tu cerebro y tu vida lo agradecerán.</p><br>
    <p>#MarianRojas #Cerebro #Cortisol #Dopamina #Atención #Confianza #ActitudPositiva #Reflexión #Bienestar #SaludMental</p>
    `,
    event: "rojas_quotes_view",
    tags: "#rojas #marketing #innovation",
    path:"/contenthub/quotes/rojas",
    url:"https://asiergonzalez.es/contenthub/quotes/rojas",
  },{
    id: 5,
    author: "Chema Alonso",
    where: "Planet25 · Madrid",
    category: "IA",
    image: "/assets/quotes/4.png",
    date: "2025",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Blade Runner: La profesión del futuro</p>
      </div>
    <br>
    <p>La IA ha evolucionado desde sistemas expertos hasta la IA generativa, superando la paridad humana. Con big data y cloud computing, hemos acelerado su desarrollo. Ahora, la IA no solo predice, sino que crea. ¿El reto? Distinguir lo real de lo generado.</p><br>
    <p>En un mundo de deepfakes y filtros, ¿qué es auténtico?</p><br>
    <p>#IA #MachineLearning #Deepfake #BladeRunner</p>

    `,
    event: "alonso_quotes_view",
    tags: "#chemaalonso #ia #innovation",
    path:"/contenthub/quotes/chemaalonso",
    url:"https://asiergonzalez.es/contenthub/quotes/chemaalonso",
  },{
    id: 4,
    author: "Chris Miller",
    where: "Chip War",
    date: "2025",
    category: "Business",
    image: "/assets/quotes/5.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Our fundamental problem is that our number one customer is also our number one competitor</p>
      </div>
    <br>
    <p>In the world of semiconductors, this quote couldn’t be more relevant. China is the largest consumer of chips globally, but at the same time, it’s heavily investing to become a leader in chip manufacturing.</p><br>
    <p>This creates a unique challenge: companies and countries that supply chips to China are helping strengthen its economy and tech capabilities, while China works to directly compete with them. It’s a delicate balance of collaboration and rivalry, and it’s reshaping global tech and geopolitics.</p><br>
    <p>What’s your take on this paradox? Let’s discuss!</p><br>
    <p>#ChipWar #Semiconductors #Technology #China #Geopolitics #Innovation #Competition #ChrisMiller #GlobalEconomy</p>

    `,
    event: "miller_quotes_view",
    tags: "#chrismiller #chipwar #semiconductors",
    path:"/contenthub/quotes/miller",
    url:"https://asiergonzalez.es/contenthub/quotes/miller",
  },{
    id: 3,
    author: "Jose Maria Alvarez-Pallete",
    where: "Interview",
    date: "2025",
    category: "Leadership",
    image: "/assets/quotes/3.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Es la tecnología la que debe estar al servicio de las personas, no al revés</p>
      </div>
    <br>
    <p>Creo que la reflexión "Es la tecnología la que debe estar al servicio de las personas, no al revés." de José María Álvarez-Pallete es muy oportuna, por la frase y por el momento.</p><br>
    <p>En un mundo donde la inteligencia artificial está redefiniendo nuestra manera de trabajar, crear y vivir, esta frase cobra más sentido que nunca. La tecnología avanza a un ritmo vertiginoso, pero... ¿realmente está mejorando nuestras vidas o nos estamos volviendo esclavos de ella?</p><br>
    <p>La IA no debería reemplazar nuestra esencia, sino potenciarla. No se trata de competir contra las máquinas, sino de usarlas como herramientas que amplifiquen nuestras capacidades. Innovar no es solo crear algoritmos más potentes, sino asegurarnos de que estén alineados con los valores humanos.</p><br>
    <p>En este período de hiperaceleración tecnológica, la clave no es solo avanzar, sino avanzar con propósito. La innovación debe tener un impacto real y positivo en la sociedad, democratizando oportunidades y mejorando la calidad de vida.</p><br>
    <p>Hagamos que la tecnología nos sirva, no al revés.</p>
    `,
    event: "pallete_quotes_view",
    tags: "#pallete #technology #leadership",
    path:"/contenthub/quotes/pallete",
    url:"https://asiergonzalez.es/contenthub/quotes/pallete",
  },{
    id: 2,
    author: "Ignacio Rivera · Presidente Hijos de Rivera",
    where: "ICEMD Innovation Summit 2024",
    category: "Marketing",
    date: "2024",
    image: "/assets/quotes/2.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Los números son el resultado de lo que haces, sin propósito no puedes liderar</p>
      </div>
    <br>
    <p>Entre las frases más destacadas de una de las conversaciones que vi con Ignacio Rivera, una de las primeras que más me llamó la atención fue: "Sin propósito no puedes liderar, los números son resultado de lo que haces" sugiriendo que la verdadera esencia de una organización no está sólo en los beneficios inmediatos, sino en el impacto duradero de sus acciones. Añadió que aquellos que piensan en una salida rápida, de cinco o seis años, probablemente se están centrando más en el profit que en el impacto real: "Si piensas en salir de una compañía en 5-6 años como inversor, no piensas en el impacto, sino el profit". Esto refuerza su creencia en construir proyectos sostenibles, de largo recorrido y con un impacto genuino.</p><br>
    <p>Una de las partes más personales de la charla fue cuando Ignacio compartió su preferencia por mantenerse fuera de su zona de confort. Esta disposición al cambio constante y a los desafíos lo mantiene en alerta y en crecimiento continuo, un recordatorio para cualquier líder que busque no solo estabilidad, sino evolución constante.</p><br>
    <p>Metafóricamente, describió el ecosistema empresarial alrededor de la empresa familiar como un planetario, donde cada elemento influye en el otro: "El ecosistema alrededor de una compañía es como un planetario donde cada planeta tiene su cometido".</p><br>
    <p>Para Rivera, el éxito de una compañía se puede resumir en cuatro pilares fundamentales: #ImpactoPositivo, <strong>#Innovación</strong>, #Marca y #Negocio. Esta simplicidad aparente oculta la profundidad de su enfoque, donde la innovación y el impacto son igual de importantes que los resultados financieros.</p><br>
    <p>Finalmente, Ignacio ofreció un consejo que bien podría ser una guía de vida: "Levántate por la mañana y haz lo que te motiva", añadiendo que la fuerza, el hambre, la humildad y la curiosidad son esenciales en el trayecto de cualquier líder. Por último, un recordatorio clave: "Rodéate de gente válida". Ningún líder triunfa solo; el equipo que lo acompaña es fundamental para cualquier proyecto con propósito.</p>

    `,
    event: "rivera_quotes_view",
    tags: "#rivera #marketing #innovation",
    path:"/contenthub/quotes/rivera",
    url:"https://asiergonzalez.es/contenthub/quotes/rivera",
  },{
    id: 1,
    author: "Valentin",
    where: "ICEMD Innovation Summit 2024",    
    date: "2024",
    category: "Marketing",
    image: "/assets/quotes/1.png",
    content: `
    <div style="width: 100%;height: auto;clear: both;float: left;position: relative;padding-left: 70px;margin-top: 10px;margin-bottom: 10px;">
        <div style="position: relative;margin-bottom: 30px">
          <img
            style="width: 40px;height: 40px"
            src="/assets/img/svg/quote.svg"
            alt="tumb"
          />
        </div>
        <p style="font-size: 20px;font-style: italic;margin-bottom: 23px">Ilia Topuria isn't just a fighter; he is a force of innovation, showing that true impoact can be as simple - and so powerful - as observing, listening, and adapting. Not just technology in the era of AI</p>
      </div>
    <br>
    <p>I have to admit, I had heard of him but hadn’t fully noticed the social phenomenon he represents—perhaps it's an age thing, if I'm honest. <strong>#IliaTopuria</strong>, more than just a fighter, has become a social phenomenon, especially among young people who see him as a symbol of hard work, dedication, and perseverance. His impact transcends sports; he is an icon embodying a winning mindset and innovative spirit, values that resonate with a generation seeking inspiration and authenticity across all spheres, from sports to marketing and life itself.</p>
    <br><p>Last Wednesday, at the <strong>#ICEMDInnovationSummit2024</strong> organized by ESIC Business & Marketing School in collaboration with Area101, I attended a talk from <strong>Valentin Hernandez Rodriguez</strong>. I had the opportunity to hear Valentín Hernández speak about innovation from a different perspective in a world where technology seems to define innovation.</p>
     <br><p>Valentín reminded us of something essential: <strong>innovation doesn’t always mean creating something new, but rather knowing how to adapt and leverage what already exists in different contexts.</strong> This approach is refreshing in an era where the “boom” of artificial intelligence and technology often overshadows the value of simplicity and common sense.</p>
     <br><p>For Valentín, innovation can draw from multiple sources:</p>
    <ul> <br>
        <li>Existing products</li>
        <li>Past mistakes</li>
        <li>Benchmarking</li>
        <li>Other industries and countries</li>
        <li>Technical support</li>
    </ul>
     <br><p>The success of <strong>Ilia Topuria</strong> is living proof of this approach. Valentín mentioned four fundamental pillars that drive the brand and, in essence, redefine marketing in the digital era:</p>
     <br><ul>
    <li><strong>Storytelling:</strong> Sharing Ilia’s story and origins connects with the audience on an emotional level.</li>
    <li><strong>Market Value:</strong> Understanding market value and tailoring the message to specific sectors.</li>
    <li><strong>Media and social media communication:</strong> Creating isn’t enough; one must effectively share and communicate.</li>
    <li><strong>Results:</strong> Measuring impact is the final factor that turns every strategy into a success or a learning experience.</li>
    </ul>
     <br><p>From my point of view, one aspect that fascinated me about this talk is how <strong>“marketing about marketing”</strong> has gained prominence. <strong>Valentin Hernandez Rodriguez</strong> was telling me how he did it, and I was hooked and it’s great to see how sharing the process of doing marketing itself becomes a form of connection. My sensation was that Valentín showed that storytelling isn’t just valid for the product but also for the strategy behind the product.</p>
     <br><p><strong>#IliaTopuria</strong> is a clear example of how, in a world driven by AI and technology, innovation can be as simple—and yet as powerful—as observing, listening, and adapting. Innovation, in the end, means continuing to find ways to connect and make an impact.</p>
    `,
    event: "topuria_quotes_view",
    tags: "#topuria #marketing #innovation",
    path:"/contenthub/quotes/topuria",
    url:"https://asiergonzalez.es/contenthub/quotes/topuria",
  }
];

export default quotes;
