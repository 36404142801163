import React, { useState } from 'react';

const BuyLink = ({ url }) => {
  // Function to open link in a new window
  const openLinkInNewWindow = () => {
    window.open(url, '_blank');
  };

  return (
    <div>
      <a onClick={openLinkInNewWindow}>Buy</a> 
    </div>
  );
};

export default BuyLink;


