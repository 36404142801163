import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery } from "react-photoswipe-gallery";
import { useLocation } from 'react-router-dom';
import Modal from "react-modal";
import projects from "./ProjectsItems";
import conferences from "./ConferencesItems";
import videos from "./VideosItems";

import ShareableLink from "../ShareableLink";

Modal.setAppElement("#root");

const Portfolio = () => {

  const location = useLocation();
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isConferenceOpen, setIsConferenceOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentProjectPage, setcurrentProjectPage] = useState(1);
  const [currentConferencePage, setcurrentConferencePage] = useState(1);
  const [currentVideoPage, setcurrentVideoPage] = useState(1);
  const [searchProjectQuery, setsearchProjectQuery] = useState("");
  const [searchConferenceQuery, setsearchConferenceQuery] = useState("");
  const [searchVideoQuery, setsearchVideoQuery] = useState("");
  const projectsPerPage = 6; // Adjust as needed
  const conferencesPerPage = 6;
  const videosPerPage = 6;

  useEffect(() => {
    const currentPath = location.pathname;
    // Call the modal toggling function based on the currentPath
    toogleModalBasedOnPath(currentPath);
  }, [location.pathname]);

  const toogleModalBasedOnPath = (currentPath) => {
    // Implement your logic to determine which modal to toggle based on the currentPath
    const project = projects.find(project => project.path === currentPath);
    const conference = conferences.find(conference => conference.path === currentPath);
    const video = videos.find(video => video.path === currentPath);

    if (project) {
      toogleModalProject(project);
    }
    if (conference) {
      toogleModalConference(conference);
    }
    if (video) {
      toogleModalVideo(video);
    }

  };

  const toogleModalProject = (project) => {
    setIsProjectOpen(isProjectOpen === project.id ? false : project.id);
    window.dataLayer.push({ 'event': project.event });
  };
  const toogleModalConference = (conference) => {
    setIsConferenceOpen(isConferenceOpen === conference.id ? false : conference.id);
    window.dataLayer.push({ 'event': conference.event });
  };
  const toogleModalVideo = (video) => {
    setIsVideoOpen(isVideoOpen === video.id ? false : video.id);
    window.dataLayer.push({ 'event': video.event });
  };

  function clickOnProjects() {
    window.dataLayer.push({ 'event': 'projects_view' });
  }

  function clickOnConferences() {
    window.dataLayer.push({ 'event': 'conferences_view' });
  }

  function clickOnVideos() {
    window.dataLayer.push({ 'event': 'media_view' });
  }
  // Sort proejcts by date in descending order
  const sortedProjects = projects.sort((a, b) => b.date - a.date);
  const sortedConferences = conferences.sort((a, b) => b.date - a.date);
  const sortedVideos = videos.sort((a, b) => b.date - a.date);

  const filteredProjects = sortedProjects.filter(project =>
    project.title.toLowerCase().includes(searchProjectQuery.toLowerCase()) ||
    project.content.toLowerCase().includes(searchProjectQuery.toLowerCase()) ||
    project.tags.toLowerCase().includes(searchProjectQuery.toLowerCase())
  );
  const filteredConferences = sortedConferences.filter(conference =>
    conference.title.toLowerCase().includes(searchConferenceQuery.toLowerCase()) ||
    conference.tags.toLowerCase().includes(searchConferenceQuery.toLowerCase())
  );
  const filteredVideos = sortedVideos.filter(video =>
    video.title.toLowerCase().includes(searchVideoQuery.toLowerCase()) ||
    video.tags.toLowerCase().includes(searchVideoQuery.toLowerCase())
  );

  const indexOfLastProject = currentProjectPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);
  const projectpaginate = (projectpageNumber) => {
    setcurrentProjectPage(projectpageNumber);
  };

  const indexOfLastConference = currentConferencePage * conferencesPerPage;
  const indexOfFirstConference = indexOfLastConference - conferencesPerPage;
  const currentConferences = filteredConferences.slice(indexOfFirstConference, indexOfLastConference);
  const conferencepaginate = (conferencepageNumber) => {
    setcurrentConferencePage(conferencepageNumber);
  };


  const indexOfLastVideo = currentVideoPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);
  const videopaginate = (pageNumber) => {
    setcurrentVideoPage(pageNumber);
  };


  const handleProjectSearch = (eproject) => {
    setsearchProjectQuery(eproject.target.value);
    setcurrentProjectPage(1); // Reset to first page when searching
  };

  const handleConferenceSearch = (econference) => {
    setsearchConferenceQuery(econference.target.value);
    setcurrentConferencePage(1); // Reset to first page when searching
  };

  const handleVideoSearch = (evideo) => {
    setsearchVideoQuery(evideo.target.value);
    setcurrentVideoPage(1); // Reset to first page when searching
  };

  return (
    <>
      <Gallery>
        <div className="container">
          <div className="tokyo_tm_portfolio">
            <div className="tokyo_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Work</span>
                  <h3 style={{ color: "#00ca77" }}>Main Work</h3>
                </div>
              </div>
            </div>
            <div className="portfolio_filter">
              <Tabs>
                <TabList>
                  <Tab onClick={clickOnProjects}>Projects</Tab>
                  <Tab onClick={clickOnConferences}>Conferences</Tab>
                  <Tab onClick={clickOnVideos}>Media & Press</Tab>
                </TabList>
                <div className="list_wrapper">
                  {/* PROJECTS */}
                  <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredProjects.length / projectsPerPage) }, (_, i) => i + 1).map((number) => (
                          <button
                            key={number}
                            onClick={() => projectpaginate(number)}
                            className={number === currentProjectPage ? "active" : ""}
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search projects"
                        value={searchProjectQuery}
                        onChange={handleProjectSearch}
                      />
                    </div>
                    <div className="portfolio_list">
                      <ul>
                        {currentProjects.map((project) => (
                          <li className="no" key={project.id} data-aos="fade-right" data-aos-duration="1200">
                            <div className="inner">
                              <div className="entry tokyo_tm_portfolio_animation_wrap">
                                <img
                                  src={project.image}
                                  alt="Details"
                                  data-tip
                                  data-for="detail"
                                  onClick={() => toogleModalProject(project)}
                                />
                              </div>
                              <div>
                                <h5>{project.title}</h5>
                                <p>{project.subtitle} · {project.category}</p>
                                <p>{project.description}</p>
                                <p>{project.tags}</p>
                              </div>
                              <ShareableLink url={project.url} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="pagination">
                      {Array.from({ length: Math.ceil(filteredProjects.length / projectsPerPage) }, (_, i) => i + 1).map((number) => (
                        <button
                          key={number}
                          onClick={() => projectpaginate(number)}
                          className={number === currentProjectPage ? "active" : ""}
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                  </TabPanel>
                  {/* CONFERENCES */}
                  <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredConferences.length / conferencesPerPage) }, (_, i) => i + 1).map((conferencenumber) => (
                          <button
                            key={conferencenumber}
                            onClick={() => conferencepaginate(conferencenumber)}
                            className={conferencenumber === currentConferencePage ? "active" : ""}
                          >
                            {conferencenumber}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search conferences"
                        value={searchConferenceQuery}
                        onChange={handleConferenceSearch}
                      />
                    </div>
                    <ul className="portfolio_list">
                      {currentConferences.map((conference) => (
                        <li className="no" key={conference.id} data-aos="fade-right" data-aos-duration="1200">
                          <div className="inner">
                            <div className="entry tokyo_tm_portfolio_animation_wrap">
                              <img
                                src={conference.image}
                                alt="Youtube"
                                data-tip
                                data-for="youtube"
                                onClick={() => toogleModalConference(conference)}
                              />
                            </div>
                            <div>
                              <h5>{conference.title} </h5>
                              <span>{conference.subtitle}</span><br />
                              <span>{conference.tags}</span>
                            </div>
                            <ShareableLink url={conference.url} />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredConferences.length / conferencesPerPage) }, (_, i) => i + 1).map((conferencenumber) => (
                          <button
                            key={conferencenumber}
                            onClick={() => conferencepaginate(conferencenumber)}
                            className={conferencenumber === currentConferencePage ? "active" : ""}
                          >
                            {conferencenumber}
                          </button>
                        ))}
                      </div>
                  </TabPanel>
                   {/* VIDEOS */}
                   <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredVideos.length / videosPerPage) }, (_, i) => i + 1).map((videonumber) => (
                          <button
                            key={videonumber}
                            onClick={() => videopaginate(videonumber)}
                            className={videonumber === currentVideoPage ? "active" : ""}
                          >
                            {videonumber}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search videos"
                        value={searchVideoQuery}
                        onChange={handleVideoSearch}
                      />
                    </div>
                    <ul className="portfolio_list">
                      {currentVideos.map((video) => (
                        <li className="no" key={video.id} data-aos="fade-right" data-aos-duration="1200">
                          <div className="inner">
                            <div className="entry tokyo_tm_portfolio_animation_wrap">
                              <img
                                src={video.image}
                                alt="Youtube"
                                data-tip
                                data-for="youtube"
                                onClick={() => toogleModalVideo(video)}
                              />
                            </div>
                            <div>
                              <h5>{video.title} </h5>
                              <span>{video.subtitle}</span><br />
                              <span>{video.tags}</span>
                            </div>
                            <ShareableLink url={video.url} />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredVideos.length / videosPerPage) }, (_, i) => i + 1).map((videonumber) => (
                          <button
                            key={videonumber}
                            onClick={() => videopaginate(videonumber)}
                            className={videonumber === currentVideoPage ? "active" : ""}
                          >
                            {videonumber}
                          </button>
                        ))}
                      </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </Gallery>
      {projects.map((project) => (
        <Modal
          isOpen={isProjectOpen === project.id}
          onRequestClose={() => toogleModalProject(project)}
          contentLabel="My dialog"
          className="mymodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
            <button className="close-modal" onClick={() => toogleModalProject(project)}>
              <img src="/assets/img/svg/cancel.svg" alt="close icon" />
            </button>
            <div className="box_inner">
              <div className="description_wrap scrollable">
                <div className="image">
                  <img src="/assets/img/thumbs/4-3.jpg" alt="tumb" />
                  <div
                    className="main"
                    style={{
                      backgroundImage: `url(${project.detailImage})`
                    }}
                  ></div>
                </div>
                <div className="portfolio_main_title">
                  <h3>{project.title}</h3>
                  <span>{project.subtitle}</span><br />
                  <span>{project.tags}</span>
                </div>
                <div className="main_details">
                  <div className="textbox">
                    <div dangerouslySetInnerHTML={{ __html: project.content }} />
                  </div>
                  <div className="detailbox">
                    <ul>
                      <li>
                        <span className="first">Company</span>
                        <span>{project.company}</span>
                      </li>
                      <li>
                        <span className="first">Date</span>
                        <span>{project.date}</span>
                      </li>
                      <li>
                        <span className="first">WebSite</span>
                        {project.website && (
                          <span><a href={project.website} target="_blank" rel="noreferrer">Link</a></span>
                        )}
                        {!project.website && (
                          <span>-<br /></span>
                        )}
                      </li>
                      <li>
                        <span className="first">Brochure</span>
                        {project.brochure && (
                          <span><a href={project.brochure} target="_blank" rel="noreferrer">Download</a></span>
                        )}
                        {!project.brochure && (
                          <span>-<br /></span>
                        )}
                      </li>
                      <li>
                        <span className="first">Press</span>
                        {project.press1 && (
                          <span><a href={project.presslink1} target="_blank" rel="noreferrer">{project.press1}</a><br /></span>
                        )}
                        {project.press2 && (
                          <span><a href={project.presslink2} target="_blank" rel="noreferrer">{project.press2}</a><br /></span>
                        )}
                        {project.press3 && (
                          <span><a href={project.presslink3} target="_blank" rel="noreferrer">{project.press3}</a><br /></span>
                        )}
                        {project.press4 && (
                          <span><a href={project.presslink4} target="_blank" rel="noreferrer">{project.press4}</a></span>
                        )}
                        {!project.press1 && (
                          <span>-<br /></span>
                        )}
                      </li>
                      <li>
                        <span className="first">Video</span>
                        {project.video && (
                          <span><a href={project.videolink1} target="_blank" rel="noreferrer">{project.video}</a><br /></span>
                        )}
                        {!project.video && (
                          <span>-<br /></span>
                        )}
                      </li>
                      <li>
                        <span className="first">Share</span>
                        <ShareableLink url={project.url} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="additional_images">
                  <ul className="gallery_zoom">
                    {project.video1 && (
                      <li>
                        <div className="list_inner">
                          <div className="my_video">
                            <iframe
                              width="560"
                              height="315"
                              src={`https://www.youtube.com/embed/${project.video1}`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      </li>
                    )}

                    {project.image1 && (
                      <li>
                        <div className="list_inner">
                          <div className="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${project.image1})`
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    )}
                    {project.image2 && (
                      <li>
                        <div className="list_inner">
                          <div className="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${project.image2})`
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    )}
                    {project.image3 && (
                      <li>
                        <div className="list_inner">
                          <div className="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${project.image3})`
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    )}
                    {project.image4 && (
                      <li>
                        <div className="list_inner">
                          <div className="my_image">
                            <img src="img/thumbs/4-2.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{
                                backgroundImage: `url(${project.image4})`
                              }}
                            ></div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            {/* END BOX INNER */}
          </div>
        </Modal>
      ))}
      {conferences.map((conference) => (
        <Modal
          isOpen={isConferenceOpen === conference.id}
          onRequestClose={() => toogleModalConference(conference)}
          contentLabel="My dialog"
          className="myvideomodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox" >
            <button className="close-modal" onClick={() => toogleModalConference(conference)}>
              <img src="assets/img/svg/cancel.svg" alt="close icon" />
            </button>
            <iframe
              class="embedded_video"
              src={`${conference.videopath}`} 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        </Modal>
      ))}
      {videos.map((video) => (
        <Modal
          isOpen={isVideoOpen === video.id}
          onRequestClose={() => toogleModalVideo(video)}
          contentLabel="My dialog"
          className="myvideomodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox" >
            <button className="close-modal" onClick={() => toogleModalVideo(video)}>
              <img src="assets/img/svg/cancel.svg" alt="close icon" />
            </button>
            <iframe
              class="embedded_video"
              src={`${video.videopath}`} 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              >
            </iframe>
          </div>
        </Modal>
      ))}
    </>
  );
};

export default Portfolio;
