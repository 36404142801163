import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useLocation } from 'react-router-dom';
import SubscribePopup from "../suitedash/SubscribePopup";
import podcastPods from "./PodcastPods";
import ShareableLink from "../ShareableLink";
import SubscribeForm from "../mailchimp/SubscribeForm";

Modal.setAppElement("#root");

const Podcast = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);
  const podsPerPage = 6; // Ajustable según necesidad

  useEffect(() => {
    const currentPath = location.pathname;
    toggleModalBasedOnPath(currentPath);
  }, [location.pathname]);

  const toggleModalBasedOnPath = (currentPath) => {
    const pod = podcastPods.find(pod => pod.path === currentPath);
    if (pod) {
      toggleModal(pod);
    }
  };

  const toggleModal = (pod) => {
    setIsOpen(prev => (prev === pod.id ? null : pod.id));
    if (pod.event) {
      window.dataLayer.push({ 'event': pod.event });
    }
  };

  // Ordena los podcasts por fecha en orden descendente
  const sortedPodcastPods = [...podcastPods].sort((a, b) => new Date(b.date) - new Date(a.date));

  // Filtrado basado en el campo de búsqueda
  const filteredPods = sortedPodcastPods.filter(pod =>
    pod.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    pod.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
    pod.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const indexOfLastPod = currentPage * podsPerPage;
  const indexOfFirstPod = indexOfLastPod - podsPerPage;
  const currentPods = filteredPods.slice(indexOfFirstPod, indexOfLastPod);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reiniciar a la primera página cuando se busca
  };

  return (
    <>
    <div className="container">
      <div className="tokyo_tm_news">
        <div className="tokyo_tm_title">
          <div className="title_flex">
            <div className="left">
              <span>PODCAST</span>
              <h3>Podcast</h3>
            </div>
            <div className="subscribe">
                {/* <SubscribeForm />  */}
                <div className="subscribe">
                <SubscribePopup
                  isOpen={isSubscribePopupOpen}
                  onClose={() => setIsSubscribePopupOpen(false)}
                />
                  <button
                    onClick={() => setIsSubscribePopupOpen(true)}
                    style={{
                      backgroundColor: "#00ca77",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "16px",
                      border: "none",
                    }}
                  >
                    Subscribe
                  </button>
                </div>
              </div>
          </div>
          <p>These podcasts constitute an exploration into the application of Artificial Intelligence to content creation.</p>
        </div>

        <div className="line">
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredPods.length / podsPerPage) }, (_, i) => i + 1).map(number => (
              <button
                key={number}
                onClick={() => paginate(number)}
                className={number === currentPage ? "active" : ""}
              >
                {number}
              </button>
            ))}
          </div>

          <input
            style={{ outline: "none" }}
            type="text"
            placeholder="Search podcasts"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>

        <ul>
          {currentPods.map((pod) => (
            <li key={pod.id} className="no" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
              <div className="list_inner">
                <div className="image" onClick={() => toggleModal(pod)}>
                  <img src="/assets/img/thumbs/1-1.jpg" alt="thumb" />
                  <div
                    className="main"
                    style={{ backgroundImage: `url(${pod.image})` }}
                  ></div>
                </div>

                <div className="details">
                  <div className="extra">
                    <p className="date">
                      By <a href="#">{pod.author}</a>
                      <span>{pod.date}</span>
                    </p>
                  </div>

                  <h3 className="title" onClick={() => toggleModal(pod)}>
                    {pod.title}
                  </h3>

                  <div>
                    <span>{pod.tags}</span>
                  </div>

                  <div className="tokyo_tm_read_more">
                    <a role="button" onClick={() => toggleModal(pod)}>
                      <span>See More</span>
                    </a>
                  </div>

                  <ShareableLink url={pod.url} />
                </div>

                <Modal
                  isOpen={isOpen === pod.id}
                  onRequestClose={() => toggleModal(pod)}
                  contentLabel="Podcast Modal"
                  className="mypodcastmodal"
                  overlayClassName="myoverlay"
                  closeTimeoutMS={500}
                >
                  <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
                    <button className="close-modal" onClick={() => toggleModal(pod)}>
                      <img src="/assets/img/svg/cancel.svg" alt="close icon" />
                    </button>

                    {pod.podcastpath && (
                      <iframe
                        src={pod.podcastpath}
                        width="100%" 
                        height="352" 
                        frameBorder="0" 
                        allowfullscreen="" 
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                        loading="lazy"
                      />
                    )}

                    <div className="box_inner" style={{ maxWidth: pod.width }}>
                      {pod.modalImage && <img src={pod.modalImage} alt="thumb" />}
                      {pod.modalContent && (
                        <div className="description_wrap scrollable">
                          <div className="details">
                            <div className="extra">
                              <p className="date">
                                By <a href="#">{pod.author}</a>
                                <span>{pod.date}</span>
                              </p>
                            </div>
                            <h3 className="title">{pod.title}</h3>
                          </div>

                          <div className="main_content">
                            <div className="descriptions">
                              <div dangerouslySetInnerHTML={{ __html: pod.modalContent }} />
                              <div className="news_share">
                                <span>{pod.tags.join(", ")}</span>
                              </div>
                              <ShareableLink url={pod.url} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Modal>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </>
  );
};

export default Podcast;
