import Social from "./Social";
import React, { useEffect, useState } from "react";
import SubscribeFormHome from "./mailchimp/SubscribeFormHome";
import SubscribePopup from "./suitedash/SubscribePopup";


const Home = () => {
  const [isSubscribePopupOpen, setIsSubscribePopupOpen] = useState(false);
  return (
    <>
      <header>
        <div className="text-center bg-image" style={{ minHeight: "100vh" }}>
          <video
            style={{ minWidth: "100%", minHeight: "100%" }}
            playsInline
            autoPlay
            muted
            loop
          >
            <source
              className="h-100"
              src="https://mdbootstrap.com/img/video/Lines.mp4"
              type="video/mp4"
            />
          </video>
          <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
            <div className="d-flex justify-content-center align-items-center h-90">
              <div className="mainText">
                <span className="mainh1">
                  Driven by <span style={{ fontWeight: "bold" }}>innovation</span>,
                  digital solutions & the <span style={{ fontWeight: "bold" }}>people</span> that use them.
                </span>
                <br />
                <img
                  className="top_author_image responsive-img"
                  src="assets/img/slider/1.png"
                  alt="Slider Image"
                  style={{
                    maxWidth: "30%",
                    height: "auto",
                    margin: "20px 0",
                    borderRadius: "10px",
                  }}
                />
                <br />
                <span className="mainh2">
                  Imagine stepping into a world where technology meets imagination, and innovation is more than a buzzword—it's a way of life.
                </span>
                <br />
                <br />
                <span className="mainh2">
                  I'm Asier González, and I'm all about turning cool ideas into real-life tech solutions. I've been at this tech game for 15 years, from the startup trenches to multinational boardrooms and I've immersed myself in the dynamic landscape of innovation creating products and making decisions that count.
                </span>
                <br />
                <br />
                <Social />
                <br />
                <div style={{ textAlign: "center", marginTop: "20px" }}>
  <SubscribePopup
    isOpen={isSubscribePopupOpen}
    onClose={() => setIsSubscribePopupOpen(false)}
  />
  <button
    onClick={() => setIsSubscribePopupOpen(true)}
    style={{
      backgroundColor: "#00ca77",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
      border: "none",
    }}
  >
    Subscribe
  </button>
</div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Home;
