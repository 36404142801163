import React from "react";
import Social from "../Social";

const Intro = () => {
  const introContent = {
    image: "assets/img/slider/1.png",
    name: "Asier González Gómez",
    slogan: "Enabling Smarter Tomorrow with Cutting-Edge Technology",
    designation: "Senior Product Manager | Telecom Engineer | MBA | Entrepreneur | Passionate about Innovation + Emerging Technologies | Global + Hybrid Profile | IoT | IA | 5G | Smart Cities | Independent Consultant + Advisor | IESE PDD",
    text: (
      <>
      
      <p>Experienced Bilingual Executive: Bridging Tech and Innovation for 15 Years</p>
      <p>As a seasoned technology professional, I bring a wealth of expertise in IoT service development, digital transformation, and innovation. My career has been defined by transforming visionary concepts into actionable solutions and driving the evolution of IoT services. With a track record of establishing strategic alliances and uncovering opportunities, I excel at charting clear roadmaps for success. I'm passionate about leveraging emerging technologies to foster innovation and deliver measurable results.</p>
      <p>My skills extend beyond the technical, as I've honed leadership, strategic thinking, and communication prowess. My approach is characterized by ingenious solutions that prioritize client satisfaction, business growth, and industry advancement.</p>
      <p>My journey has revolved around the dynamic IoT landscape, where I've led the ideation and curation of IoT product portfolios. I'm adept at conducting in-depth market analysis, crafting robust product specifications, and architecting technical reference frameworks. My career highlights include pioneering solutions for connected buildings, elevating air quality monitoring systems, and revolutionizing smart agriculture and smart water strategies. I've navigated the fast-evolving IoT landscape with agility, helping businesses transform and thrive.</p>
      <p>I excel at collaborating seamlessly with cross-functional teams, fostering an environment where innovative ideas flourish. My expertise in IoT has allowed me to tailor strategies for business growth and leverage data for informed decisions. Through collaboration and innovation, I've contributed to driving business transformation and ensuring sustained success.</p>
     <p>My career has encompassed various facets of the technology landscape, from creating innovative products and services, independent consulting and project evaluation to lecturing and speaking on IoT technologies. I've had the privilege of contributing to Big Companies, European ICT projects and educating the next generation of IoT leaders. My passion extends to driving transformation in water, energy, and critical infrastructures, where I've led IoT initiatives and cultivated skilled teams.</p>
      <p>My hallmark lies in my customer-centric approach. I harmonize business objectives, client needs, and development teams by blending my strategic acumen, technical insights, and empathetic perspective. This holistic and cross-functional strategy fosters a positive, collaborative environment that resonates with a cheerful spirit, ensuring that every endeavor is infused with unwavering enthusiasm.</p>
      </>
    ),
  };

  return (
    <>
      <div className="top_author_image">
        <img src={introContent.image} alt="about" />
      </div>
      <div className="about_title">
        <h3>{introContent.name}</h3>
        <br/>
        <span>{introContent.designation}</span>
        <br/>
        <br/>
        <div> <Social /></div>
      </div>
      <div className="about_text">
      <h6>{introContent.slogan}</h6><br></br>
      <span>{introContent.text}</span></div>
     
    </>
  );
};

export default Intro;
