import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./SubscribePopup.css";

const SubscribePopup = ({ isOpen, onClose }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (isOpen && containerRef.current) {
      // Limpiar el contenedor para evitar duplicados
      containerRef.current.innerHTML = "";

      const script = document.createElement("script");
      script.src = "https://portal.allier.io/frm/2w4UVPWfw4jWCDREq.js";
      script.async = true;
      script.defer = true;
      containerRef.current.appendChild(script);
    }

    // Bloquea el scroll del body cuando el popup está abierto
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="subscribe-popup-overlay" onClick={onClose}>
      <div
        className="subscribe-popup-modal"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="subscribe-popup-close" onClick={onClose}>
          ✕
        </button>
        <h4 className="subscribe-popup-title">
          Subscribe to My Monthly Content Newsletter
        </h4>
        <p className="subscribe-popup-description">
          Get the latest updates and insights directly to your inbox.
        </p>
        <div ref={containerRef} className="subscribe-popup-form-container"></div>
      </div>
    </div>,
    document.body // O, alternativamente, otro contenedor como: document.getElementById('modal-root')
  );
};

export default SubscribePopup;
