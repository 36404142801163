const books = [
  {
    "id": 27,
    "title": "Tech Transfer Best Practices",
    "subtitle": "HBS Rock Center for Entrepreneurship",
    "image": "/assets/talks/tech_transfer_best_practices.png",
    "event": "hbs_rock_center_tech_transfer_view",
    "tags": "#talks #techtransfer #licensing #IP #HBS",
    "date": "5 September 2023",
    "path": "/talks/tech_transfer_best_practices",
    "url": "https://asiergonzalez.es/talks/tech_transfer_best_practices",
    "talkpath": "https://www.youtube.com/embed/q2ruRHdsoC8"
}
,{
    "id": 26,
    "title": "Decision Analysis in Venture Capital",
    "subtitle": "Clint Korver",
    "image": "/assets/talks/vc_decision_analysis.png",
    "event": "stanford_decision_ethics_view",
    "tags": "#talks #venturecapital #decisionmaking #stanford #clintkorver",
    "date": "27 February 2018",
    "path": "/talks/vc_decision_analysis",
    "url": "https://asiergonzalez.es/talks/vc_decision_analysis",
    "talkpath": "https://www.youtube.com/embed/Wi3PiZsIfBU"
}
,{
    "id": 25,
    "title": "Building an Investment Thesis",
    "subtitle": "Michael Skok",
    "image": "/assets/talks/vc_investment_thesis_hbs.png",
    "event": "hbs_rock_center_vc_view",
    "tags": "#talks #venturecapital #investmentthesis #HBS #michaelskok",
    "date": "31 January 2023",
    "path": "/talks/vc_investment_thesis_hbs",
    "url": "https://asiergonzalez.es/talks/vc_investment_thesis_hbs",
    "talkpath": "https://www.youtube.com/embed/DsiDES1JOQc"
}
,{
    "id": 24,
    "title": "Investment Thesis Fundamentals",
    "subtitle": "Bedy Yang",
    "image": "/assets/talks/vc_investment_thesis.png",
    "event": "500_global_vc_unlocked_view",
    "tags": "#talks #venturecapital #investment #bedyyang #500global",
    "date": "30 March 2018",
    "path": "/talks/vc_investment_thesis",
    "url": "https://asiergonzalez.es/talks/vc_investment_thesis",
    "talkpath": "https://www.youtube.com/embed/Q7L_SWelLIQ"
}
,{
    "id": 23,
    "title": "HBS VC Pathways Session 1 - Is VC for Me?",
    "subtitle": "Michael Skok & Guests",
    "image": "/assets/talks/vc_pathways.png",
    "event": "hbs_rock_center_vc_view",
    "tags": "#talks #venturecapital #HBS #michaelskok",
    "date": "22 December 2022",
    "path": "/talks/vc_pathways",
    "url": "https://asiergonzalez.es/talks/vc_pathways",
    "talkpath": "https://www.youtube.com/embed/nhM9jctbTm8"
}
,{
    "id": 22,
    "title": "Building Scalable Products",
    "subtitle": "Chris Gardner",
    "image": "/assets/talks/building_scalable_products.png",
    "event": "harvard_innovation_labs_view",
    "tags": "#talks #startups #scalability #productdevelopment #chrisgardner",
    "date": "5 April 2023",
    "path": "/talks/building_scalable_products",
    "url": "https://asiergonzalez.es/talks/building_scalable_products",
    "talkpath": "https://www.youtube.com/embed/r-98YRAF1dY"
}
,{
    "id": 21,
    "title": "Selling Your Startup",
    "subtitle": "Thibauld Favre",
    "image": "/assets/talks/selling_your_startup.png",
    "event": "startupfood_exit_lessons_view",
    "tags": "#talks #exitstrategy #startups #thibauldfavre",
    "date": "1 November 2018",
    "path": "/talks/selling_your_startup",
    "url": "https://asiergonzalez.es/talks/selling_your_startup",
    "talkpath": "https://www.youtube.com/embed/7qKxwP-664E"
}
,{
    "id": 20,
    "title": "Exit Strategy",
    "subtitle": "Basil Peters",
    "image": "/assets/talks/exit_strategy.png",
    "event": "strategic_exits_workshop_view",
    "tags": "#talks #exitstrategy #startups #basilpeters",
    "date": "1 February 2022",
    "path": "/talks/exit_strategy",
    "url": "https://asiergonzalez.es/talks/exit_strategy",
    "talkpath": "https://www.youtube.com/embed/MA5tzY2tbqI"
}
,{
    "id": 19,
    "title": "Don't Fail Fast - Fail Mindfully",
    "subtitle": "Leticia Gasca",
    "image": "/assets/talks/fail_mindfully.png",
    "event": "ted_talks_failure_view",
    "tags": "#talks #tedtalks #failure #mindfulness #leticiagasca",
    "date": "13 September 2018",
    "path": "/talks/fail_mindfully",
    "url": "https://asiergonzalez.es/talks/fail_mindfully",
    "talkpath": "https://www.youtube.com/embed/IOABs1cOqDo"
}
,{
    "id": 18,
    "title": "Vulnerability Makes You a Better Leader",
    "subtitle": "Tracy Young",
    "image": "/assets/talks/vulnerability_leadership.png",
    "event": "ted_talks_leadership_view",
    "tags": "#talks #tedtalks #leadership #vulnerability #tracyyoung",
    "date": "2 March 2021",
    "path": "/talks/vulnerability_leadership",
    "url": "https://asiergonzalez.es/talks/vulnerability_leadership",
    "talkpath": "https://www.youtube.com/embed/uOvK8roYVUU"
}
,{
    "id": 17,
    "title": "How to Pitch to a VC Investor",
    "subtitle": "David S. Rose",
    "image": "/assets/talks/pitch_to_vc.png",
    "event": "ted_talks_vc_pitch_view",
    "tags": "#talks #tedtalks #venturecapital #pitch #davidrose",
    "date": "29 September 2008",
    "path": "/talks/pitch_to_vc",
    "url": "https://asiergonzalez.es/talks/pitch_to_vc",
    "talkpath": "https://www.youtube.com/embed/lzDBrMisLm0"
},{
  "id": 16,
  "title": "5 Ways to Kill Your Dreams",
  "subtitle": "Bel Pesce",
  "image": "/assets/talks/kill_your_dreams.png",
  "event": "ted_talks_dreams_view",
  "tags": "#talks #tedtalks #dreams #belpesce",
  "date": "6 April 2015",
  "path": "/talks/kill_your_dreams",
  "url": "https://asiergonzalez.es/talks/kill_your_dreams",
  "talkpath": "https://www.youtube.com/embed/sR6P5Qdvlnk"
},
{
    "id": 15,
    "title": "Your Body Language Shapes Who You Are",
    "subtitle": "Amy Cuddy",
    "image": "/assets/talks/body_language_identity.png",
    "event": "ted_talks_bodylanguage_view",
    "tags": "#talks #tedtalks #bodylanguage #amycuddy",
    "date": "1 October 2012",
    "path": "/talks/body_language_identity",
    "url": "https://asiergonzalez.es/talks/body_language_identity",
    "talkpath": "https://www.youtube.com/embed/Ks-_Mh1QhMc"
}
,{
    "id": 14,
    "title": "The Biggest Reason Why Start-ups Succeed",
    "subtitle": "Bill Gross",
    "image": "/assets/talks/startup_success.png",
    "event": "ted_talks_startups_view",
    "tags": "#talks #tedtalks #startups #billgross",
    "date": "1 June 2015",
    "path": "/talks/startup_success",
    "url": "https://asiergonzalez.es/talks/startup_success",
    "talkpath": "https://www.youtube.com/embed/bNpx7gpSqbY"
}
,{
    "id": 13,
    "title": "The Power of Vulnerability",
    "subtitle": "Brené Brown",
    "image": "/assets/talks/power_of_vulnerability.png",
    "event": "ted_talks_vulnerability_view",
    "tags": "#talks #tedtalks #brenebrown #vulnerability",
    "date": "3 January 2011",
    "path": "/talks/power_of_vulnerability",
    "url": "https://asiergonzalez.es/talks/power_of_vulnerability",
    "talkpath": "https://www.youtube.com/embed/iCvmsMzlF7o"
}
,{
    "id": 12,
    "title": "Seth Godin: How to Stand Out",
    "subtitle": "Seth Godin",
    "image": "/assets/talks/seth_godin_stand_out.png",
    "event": "ted_talks_marketing_view",
    "tags": "#talks #tedtalks #marketing #sethgodin",
    "date": "17 May 2007",
    "path": "/talks/seth_godin_stand_out",
    "url": "https://asiergonzalez.es/talks/seth_godin_stand_out",
    "talkpath": "https://www.youtube.com/embed/xBIVlM435Zg"
}
,{
    "id": 11,
    "title": "Success, Failure and Keep Creating",
    "subtitle": "Elizabeth Gilbert",
    "image": "/assets/talks/success_failure_creativity.png",
    "event": "ted_talks_creativity_view",
    "tags": "#talks #tedtalks #creativity #elizabethgilbert",
    "date": "8 January 2017",
    "path": "/talks/success_failure_creativity",
    "url": "https://asiergonzalez.es/talks/success_failure_creativity",
    "talkpath": "https://www.youtube.com/embed/UZBf3rDoEl0"
},{
    "id": 10,
    "title": "How to Negotiate",
    "subtitle": "Deepak Malhotra",
    "image": "/assets/talks/how_to_negotiate.png",
    "event": "negotiation_talks_view",
    "tags": "#talks #negotiation #deepakmalhotra",
    "date": "10 December 2018",
    "path": "/talks/how_to_negotiate",
    "url": "https://asiergonzalez.es/talks/how_to_negotiate",
    "talkpath": "https://www.youtube.com/embed/bUi5R54Oqig"
  },
{
    "id": 9,
    "title": "Marketing is About Values",
    "subtitle": "Steve Jobs",
    "image": "/assets/talks/steve_jobs_marketing.png",
    "event": "stevejobs_marketing_talks_view",
    "tags": "#talks #stevejobs #marketing",
    "date": "Fecha del video o evento",
    "path": "/talks/steve_jobs_marketing",
    "url": "https://asiergonzalez.es/talks/steve_jobs_marketing",
    "talkpath": "https://www.youtube.com/embed/4mvHgLy_YV8"
},
{
    "id": 8,
    "title": "Estrategias en el Oceano Rojo y Azul",
    "subtitle": "Luis Ramos",
    "image": "/assets/talks/oceanorojoyazul.png",
    "event": "oceanorojoazul_talks_view",
    "tags": "#talks #startup",
    "date": "28 September 2018",
    "path": "/talks/oceanorojoyazul",
    "url": "https://asiergonzalez.es/talks/oceanorojoyazul",
    "talkpath": "https://www.youtube.com/embed/qQup5DFRBPw"
},{
    id: 7,
    title: "The Lean Startup",
    subtitle: "Eric Ries",
    image: "/assets/talks/theleanstartup.png",
    event: "theleanstartup_talks_view",
    tags: "#talks #theleanstartup",
    date: "9 February 2016",
    path:"/talks/theleanstartup",
    url:"https://asiergonzalez.es/talks/theleanstartup",
    talkpath:"https://www.youtube.com/embed/sobxOzRjAGg"
  },{
    id: 6,
    title: "Liderazgo Inspirador",
    subtitle: "Ignacio Rivera",
    image: "/assets/talks/ignaciorivera.png",
    event: "ignaciorivera_talks_view",
    tags: "#talks #ignaciorivera",
    date: "17 November 2024",
    path:"/talks/ignaciorivera",
    url:"https://asiergonzalez.es/talks/ignaciorivera",
    talkpath:"https://www.youtube.com/embed/cOX5R0OF5JU?si=il2z2MCo_TTpvH8O&amp;start=6162"
  },{
    id: 5,
    title: "Illia Topuria · Innovar en el Negocio",
    subtitle: "Valentín Hernandez",
    image: "/assets/talks/illiatopuria.png",
    event: "illiatopuria_talks_view",
    tags: "#talks #illiatopuria",
    date: "17 November 2024",
    path:"/talks/illiatopuria",
    url:"https://asiergonzalez.es/talks/illiatopuria",
    talkpath:"https://www.youtube.com/embed/cOX5R0OF5JU?si=Y_atOgpzJvp7G8Wu&amp;start=877"
  },{
    id: 4,
    title: "The Era of Open Innovation",
    subtitle: "Adrian Johnson",
    image: "/assets/talks/eraopeninnovation.png",
    event: "eraopeninnovation_talks_view",
    tags: "#talks #openinnovation",
    date: "2017",
    path:"/talks/eraopeninnovation",
    url:"https://asiergonzalez.es/talks/eraopeninnovation",
    talkpath:"https://www.youtube.com/embed/8t4Vj6lmzVo"
  },{
    id: 3,
    title: "Ideas for Change",
    subtitle: "Henry Chesbrough",
    image: "/assets/talks/ideas4change.png",
    event: "ideas4change_talks_view",
    tags: "#talks #ideasforchange",
    date: "2012",
    path:"/talks/ideasforchange",
    url:"https://asiergonzalez.es/talks/ideasforchange",
    talkpath:"https://www.youtube.com/embed/02tCs3oKovc"
  },{
    id: 2,
    title: "Great Leaders ",
    subtitle: "Simon Sinek",
    image: "/assets/talks/greatleader.png",
    event: "greatleader_talks_view",
    tags: "#talks #greatleader",
    date: "2010",
    path:"/talks/greatleader",
    url:"https://asiergonzalez.es/talks/greatleader",
    talkpath:"https://www.youtube.com/embed/qp0HIF3SfI4"
  },{
    id: 1,
    title: "Open Innovation",
    subtitle: "Henry Chesbrough",
    image: "/assets/talks/openinnovation.png",
    event: "openinnovation_talks_view",
    tags: "#talks #openinnovation",
    date: "2017",
    path:"/talks/openinnovation",
    url:"https://asiergonzalez.es/talks/openinnovation",
    talkpath:"https://www.youtube.com/embed/2UDBaDtwXfI"
  }
];

export default books;
